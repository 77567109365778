.form-wrap {
    position: relative;
    height: 50px;
    width: 400px;
    
    .line {
        pointer-events: none;
    }
    
    .input-elm {
        color: #000;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        padding-top: 20px;
        border: none;
        font-size: 1em;
        font-weight: 200;
        background: none;
        opacity: 0;
        font-family: 'Roboto Mono', monospace;
        transition: all 0.5s ease;
        outline: none;
    }
    
    .active input {
        opacity: 1;
        transition: all 0.5s ease 0.5s;
    }
    
    svg {
        position: absolute;
        left: 0;
        top: 100%;
        overflow: visible;
        fill: none;
        z-index: 5;
        stroke: #000;
        stroke-width: 3px;
    }
    
    label {
        color: #000;
        position: absolute;
        top: 15px;
        font-size: 1.3em;
        transition: all 0.5s ease;
    }
    
    .active label {
        top: 0px;
        font-size: 1em;
        transition: all 0.5s ease 0.3s;
    }
    
    .active2+svg {
        stroke: rgb(0, 128, 255);
    }
    
    .active2 label {
        color: rgb(0, 128, 255);
    }
    
    .active+svg {
        transition: stroke 0.4s ease 0.3s;
    }
    
    .input {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    
    .input:before {
        content: "";
        position: absolute;
        height: 26px;
        width: 3px;
        right: 15px;
        bottom: -50px;
        background-color: transparent;
        transform-origin: inherit;
        transform: rotate(0deg);
        transition: transform 0.4s ease, bottom 0.4s ease 0.3s, background-color 0.2s ease 0.6s;
    }
    
    .input:after {
        content: "";
        position: absolute;
        height: 26px;
        width: 3px;
        right: 15px;
        bottom: -50px;
        visibility: hidden;
        background-color: transparent;
        transform-origin: inherit;
        transform: rotate(0deg);
        transition: transform 0.4s ease, visibility 0.4s ease, bottom 0.4s ease 0.3s, background-color 0.2s ease 0.6s, height 0.2s ease 0.6s;
    }
    
    .valid:before {
        bottom: 10px;
        right: 16px;
        visibility: visible;
        background-color: #4CAF50;
        transform-origin: inherit;
        transform: rotate(40deg);
        transition: bottom 0.4s ease, transform 0.4s ease 0.3s;
    }
    
    .valid:after {
        bottom: 10px;
        height: 15px;
        visibility: visible;
        background-color: #4CAF50;
        transform-origin: inherit;
        transform: rotate(-45deg);
        transition: bottom 0.4s ease, visibility 0.4s ease 0.3s, transform 0.4s ease 0.3s;
    }
    
    .invalid:before {
        bottom: 10px;
        visibility: visible;
        background-color: #ff5252;
        transform: rotate(40deg);
        transition: bottom 0.4s ease, transform 0.4s ease 0.3s;
    }
    
    .invalid:after {
        height: 26px;
        bottom: 10px;
        visibility: visible;
        background-color: #ff5252;
        transform: rotate(-45deg);
        transition: bottom 0.4s ease, visibility 0.4s ease 0.3s, transform 0.4s ease 0.3s;
    }
}