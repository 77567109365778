#end {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    img {
        width: 300px;
    }

    p {
        max-width: 400px;
        font-size: 22px;
        text-align: center;
    }

    h1 {
        font-size: 7vw;
    }
}