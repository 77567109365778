@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

@import '../../pages/SelectWords/selectWords';
@import '../../components/WordCard/wordCard';
@import '../../pages/Game/components/Timer/timer';
@import '../../components/PopUp/popUp';
@import '../../pages/RankWords/rankWords';
@import '../../components/Button/button';
@import '../../pages/Start/start';
@import '../../pages/Welcome/welcome';
@import '../../components/Section/section';
@import '../../components/Input/input';
@import '../../pages/Game/game';
@import '../../pages/End/end';

/* Code couleur du logo */
$CARE_Gris: #7F7F7F;
$CARE_Noir: #000000;
$CARE_Bleu: #002060;
$CARE_Rouge: #FF0000;

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.error {
    color: red;
}

h3 {
	color: $CARE_Rouge;
}

h4 {
	color: $CARE_Gris;
}