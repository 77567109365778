.timer {
    width: 100%;
    text-align: center;
    padding: 30px;
    span {
        font-weight: 700;
    }
}

.red {
    color: red;
}

.timer-bar {
    position: absolute;
    right: 2.5%;
    height: 90%;
    top: 50%;
    transform: translateY(-50%) scale(-1);
    border-radius: 10px;
    background-color: #e3e3e3;

    &--innert {
        width: 15px;
        transition: all 1s linear;
        border-radius: 5px;
    }

    &::after {
        content: '0';
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%) scale(-1);
    }

    &::before {
        content: '68';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%) scale(-1);
    }

    .bars {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 99;
        width: 100%;

        .bar {
            background-color: #000;
            height: 1px;
            width: 100%;
            display: block;
            margin-top: calc((90vh/68)*10);
        }
    }
}