#rankWords {
    padding: 15px;
    overflow: hidden;

    .wordCards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        row-gap: 5px;
        column-gap: 5px;
        
        .wordCard {
            background-color: #fff;
            color: #000;
            border: solid 2px rgb(219, 219, 219);
            position: relative;
            overflow: visible;

            &:not(.active) {
                opacity: 0;
                pointer-events: none;
            }

            span {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -20%) scale(0);
                opacity: 0;
                background-color: red;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
                border-radius: 20px;
                animation: bounce 0.5s linear forwards;
            }
            
        }
    }

    .popUp {
        h2 {
            white-space: nowrap;
            margin-bottom: 20px;
        }

        button {
            margin: auto;
        }
    }
}

@keyframes bounce {
    0% { transform:  translate(-50%, -20%) scale(1.1); opacity: 1 }
    50% { transform:  translate(-50%, -20%) scale(1.6); opacity: .7; }
    60% { transform:  translate(-50%, -20%) scale(0.6); opacity: 1 }
    80% { transform:  translate(-50%, -20%) scale(0.95); opacity: 1 }
    100% { transform:  translate(-50%, -20%) scale(0.85); opacity: 1 }
  }