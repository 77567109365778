.popUp {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 18px;
        cursor: pointer;

        img {
            height: 100%;
        }
    }

    .overlay {
        background-color: #000;
        opacity: 0;
        height: 100%;
        width: 100%;
        animation: fade 1s forwards;
    }

    h2 {
        white-space: nowrap;
    }

    .popUp-content {
        padding: 50px;
        background-color: #fff;
        border-radius: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        animation: fadeUp 1s 0.5s forwards;
        max-width: 90%;
        max-height: 90vh;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
@keyframes fadeUp {
    0% {
        opacity: 1;
        transform: translate(-50%, 50px);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

