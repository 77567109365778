#selectWords {
    padding: 15px;
    overflow: hidden;

    .wordCards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        row-gap: 5px;
        column-gap: 5px;
    }
}