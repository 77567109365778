.wordCard {
    font-size: 17px;
    font-weight: 700;
    width: calc(25% - 40px);
    padding: 5px 15px;
    border: solid 2px rgb(219, 219, 219);
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;

    &.active {
        border: none;
        background-color: rgb(83, 206, 255);
        color: #fff;
    }
}