#start {
    .start-container {
        display: flex;
        flex-direction: column;
    }

    .illustration {
        height: 200px;
        width: 90%;
        object-fit: contain;
        margin: auto;
        display: block;
        margin-bottom: 50px;
    }

    h2 {
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 30px;
        height: 36px;
    }

    p {
        font-size: 16px;
        max-width: 400px;
        margin-bottom: 20px;
    }

    .custom-button-full {
        margin-left: auto;
    }

    .rules {
        display: flex;
        column-gap: 50px;
        flex-wrap: wrap;
        row-gap: 40px;

       .rule {
        margin-bottom: 40px;
            h1 {
                font-size: 40px;
                font-weight: 900;
                margin-bottom: 20px;
            }

            p {
                font-size: 25px;
            }

            ol {
                margin-bottom: 20px;
                font-size: 25px;
            }

            span {
                color: red;
                font-weight: 700;
            }
       }
    }
}