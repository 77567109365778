#welcome {
    .illustration {
        height: 150px;
        object-fit: contain;
    }

    h1 {
        text-align: center;
    }

    .welcome-container {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        align-items: center;
    }

    #Sx_H { margin-left: 2em; }


    .rows {
        display: flex;
        column-gap: 50px;
        flex-wrap: wrap;
        justify-content: center;

        .row {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
        }
    }
}