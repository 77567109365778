@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
#selectWords {
  padding: 15px;
  overflow: hidden;
}
#selectWords .wordCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  -moz-column-gap: 5px;
       column-gap: 5px;
}

.wordCard {
  font-size: 17px;
  font-weight: 700;
  width: calc(25% - 40px);
  padding: 5px 15px;
  border: solid 2px rgb(219, 219, 219);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}
.wordCard.active {
  border: none;
  background-color: rgb(83, 206, 255);
  color: #fff;
}

.timer {
  width: 100%;
  text-align: center;
  padding: 30px;
}
.timer span {
  font-weight: 700;
}

.red {
  color: red;
}

.timer-bar {
  position: absolute;
  right: 2.5%;
  height: 90%;
  top: 50%;
  transform: translateY(-50%) scale(-1);
  border-radius: 10px;
  background-color: #e3e3e3;
}
.timer-bar--innert {
  width: 15px;
  transition: all 1s linear;
  border-radius: 5px;
}
.timer-bar::after {
  content: "0";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%) scale(-1);
}
.timer-bar::before {
  content: "68";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%) scale(-1);
}
.timer-bar .bars {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 99;
  width: 100%;
}
.timer-bar .bars .bar {
  background-color: #000;
  height: 1px;
  width: 100%;
  display: block;
  margin-top: 13.2352941176vh;
}

.popUp {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.popUp .close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 18px;
  cursor: pointer;
}
.popUp .close img {
  height: 100%;
}
.popUp .overlay {
  background-color: #000;
  opacity: 0;
  height: 100%;
  width: 100%;
  animation: fade 1s forwards;
}
.popUp h2 {
  white-space: nowrap;
}
.popUp .popUp-content {
  padding: 50px;
  background-color: #fff;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  animation: fadeUp 1s 0.5s forwards;
  max-width: 90%;
  max-height: 90vh;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translate(-50%, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
#rankWords {
  padding: 15px;
  overflow: hidden;
}
#rankWords .wordCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
#rankWords .wordCards .wordCard {
  background-color: #fff;
  color: #000;
  border: solid 2px rgb(219, 219, 219);
  position: relative;
  overflow: visible;
}
#rankWords .wordCards .wordCard:not(.active) {
  opacity: 0;
  pointer-events: none;
}
#rankWords .wordCards .wordCard span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -20%) scale(0);
  opacity: 0;
  background-color: red;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
  border-radius: 20px;
  animation: bounce 0.5s linear forwards;
}
#rankWords .popUp h2 {
  white-space: nowrap;
  margin-bottom: 20px;
}
#rankWords .popUp button {
  margin: auto;
}

@keyframes bounce {
  0% {
    transform: translate(-50%, -20%) scale(1.1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -20%) scale(1.6);
    opacity: 0.7;
  }
  60% {
    transform: translate(-50%, -20%) scale(0.6);
    opacity: 1;
  }
  80% {
    transform: translate(-50%, -20%) scale(0.95);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -20%) scale(0.85);
    opacity: 1;
  }
}
.custom-button-full {
  background-color: #000;
  color: #fff;
  padding: 15px 50px;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.custom-button-full span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-button-full span img {
  width: 18px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.custom-button-full span .content {
  transform: translateX(9px);
  transition: all 0.3s ease-in-out;
}
.custom-button-full:hover span img {
  opacity: 1;
  transform: translateX(10px);
}
.custom-button-full:hover span .content {
  transform: translateX(0);
}

/* SUCESS */
@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#successAnimationCircle {
  stroke-dasharray: 151px 36px;
  stroke: #FFF;
}

#successAnimationCheck {
  stroke-dasharray: 151px 36px;
  stroke: #FFF;
}

#successAnimationResult {
  fill: #FFF;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;
}

#successAnimationCircle {
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimationCheck {
  animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}

#successAnimationResult {
  animation: 0.3s linear 0.9s both fadeIn;
}

#successAnimation {
  display: block;
  width: 40px;
  height: 40px;
}

#start .start-container {
  display: flex;
  flex-direction: column;
}
#start .illustration {
  height: 200px;
  width: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
  display: block;
  margin-bottom: 50px;
}
#start h2 {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 30px;
  height: 36px;
}
#start p {
  font-size: 16px;
  max-width: 400px;
  margin-bottom: 20px;
}
#start .custom-button-full {
  margin-left: auto;
}
#start .rules {
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  flex-wrap: wrap;
  row-gap: 40px;
}
#start .rules .rule {
  margin-bottom: 40px;
}
#start .rules .rule h1 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
}
#start .rules .rule p {
  font-size: 25px;
}
#start .rules .rule ol {
  margin-bottom: 20px;
  font-size: 25px;
}
#start .rules .rule span {
  color: red;
  font-weight: 700;
}

#welcome .illustration {
  height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
#welcome h1 {
  text-align: center;
}
#welcome .welcome-container {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  align-items: center;
}
#welcome #Sx_H {
  margin-left: 2em;
}
#welcome .rows {
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
}
#welcome .rows .row {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.custom-section {
  min-height: 100vh;
  width: 100%;
  padding: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrap {
  position: relative;
  height: 50px;
  width: 400px;
}
.form-wrap .line {
  pointer-events: none;
}
.form-wrap .input-elm {
  color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding-top: 20px;
  border: none;
  font-size: 1em;
  font-weight: 200;
  background: none;
  opacity: 0;
  font-family: "Roboto Mono", monospace;
  transition: all 0.5s ease;
  outline: none;
}
.form-wrap .active input {
  opacity: 1;
  transition: all 0.5s ease 0.5s;
}
.form-wrap svg {
  position: absolute;
  left: 0;
  top: 100%;
  overflow: visible;
  fill: none;
  z-index: 5;
  stroke: #000;
  stroke-width: 3px;
}
.form-wrap label {
  color: #000;
  position: absolute;
  top: 15px;
  font-size: 1.3em;
  transition: all 0.5s ease;
}
.form-wrap .active label {
  top: 0px;
  font-size: 1em;
  transition: all 0.5s ease 0.3s;
}
.form-wrap .active2 + svg {
  stroke: rgb(0, 128, 255);
}
.form-wrap .active2 label {
  color: rgb(0, 128, 255);
}
.form-wrap .active + svg {
  transition: stroke 0.4s ease 0.3s;
}
.form-wrap .input {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.form-wrap .input:before {
  content: "";
  position: absolute;
  height: 26px;
  width: 3px;
  right: 15px;
  bottom: -50px;
  background-color: transparent;
  transform-origin: inherit;
  transform: rotate(0deg);
  transition: transform 0.4s ease, bottom 0.4s ease 0.3s, background-color 0.2s ease 0.6s;
}
.form-wrap .input:after {
  content: "";
  position: absolute;
  height: 26px;
  width: 3px;
  right: 15px;
  bottom: -50px;
  visibility: hidden;
  background-color: transparent;
  transform-origin: inherit;
  transform: rotate(0deg);
  transition: transform 0.4s ease, visibility 0.4s ease, bottom 0.4s ease 0.3s, background-color 0.2s ease 0.6s, height 0.2s ease 0.6s;
}
.form-wrap .valid:before {
  bottom: 10px;
  right: 16px;
  visibility: visible;
  background-color: #4CAF50;
  transform-origin: inherit;
  transform: rotate(40deg);
  transition: bottom 0.4s ease, transform 0.4s ease 0.3s;
}
.form-wrap .valid:after {
  bottom: 10px;
  height: 15px;
  visibility: visible;
  background-color: #4CAF50;
  transform-origin: inherit;
  transform: rotate(-45deg);
  transition: bottom 0.4s ease, visibility 0.4s ease 0.3s, transform 0.4s ease 0.3s;
}
.form-wrap .invalid:before {
  bottom: 10px;
  visibility: visible;
  background-color: #ff5252;
  transform: rotate(40deg);
  transition: bottom 0.4s ease, transform 0.4s ease 0.3s;
}
.form-wrap .invalid:after {
  height: 26px;
  bottom: 10px;
  visibility: visible;
  background-color: #ff5252;
  transform: rotate(-45deg);
  transition: bottom 0.4s ease, visibility 0.4s ease 0.3s, transform 0.4s ease 0.3s;
}

#game .game-btn {
  margin: auto;
}

#end {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
#end img {
  width: 300px;
}
#end p {
  max-width: 400px;
  font-size: 22px;
  text-align: center;
}
#end h1 {
  font-size: 7vw;
}

/* Code couleur du logo */
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.error {
  color: red;
}

h3 {
  color: #FF0000;
}

h4 {
  color: #7F7F7F;
}/*# sourceMappingURL=styles.css.map */