.custom-button-full{
    background-color: #000;
    color: #fff;
    padding: 15px 50px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    display: block;
    width: fit-content;
    user-select: none;

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 18px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }

        .content {
            transform: translateX(9px);
            transition: all 0.3s ease-in-out;
        }
    }

    &:hover {
        span {
    
            img {
                opacity: 1;
                transform: translateX(10px);
            }
    
            .content {
                transform: translateX(0);
            }
        }
    }
}

/* SUCESS */
@keyframes scaleAnimation {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes drawCircle {
    0% {
      stroke-dashoffset: 151px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes drawCheck {
    0% {
      stroke-dashoffset: 36px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
  #successAnimationCircle {
    stroke-dasharray: 151px 36px;
    stroke: #FFF;
  }
  
  #successAnimationCheck {
    stroke-dasharray: 151px 36px;
    stroke: #FFF;
  }
  
  #successAnimationResult {
    fill: #FFF;
    opacity: 0;
  }
  
  #successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;
  }
  
  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }

  #successAnimation {
    display: block;
    width: 40px;
    height: 40px;
  }